import { graphql, useStaticQuery } from "gatsby"
import { useEffect, useState } from "react"
import axios from "axios"
import { useSiteState } from "../context/siteContext"

const useCheckoutSettings = (productId = "") => {
  const [frequencyOptions, setFrequencyOptions] = useState(null)
  const siteState = useSiteState()
  const { frequencyMap } = siteState
  const settings = useStaticQuery(graphql`
    query {
      sanitySiteSettings(id: { eq: "-0f217bb5-f7f6-5420-b7c6-58db2c12b8c7" }) {
        rechargeDiscount
        minShipping
      }
    }
  `)

  const rechargeDiscount = settings.sanitySiteSettings.rechargeDiscount
  const minShipping = settings.sanitySiteSettings.minShipping

  useEffect(() => {
    const getFrequencyOptions = () => {
      axios({
        url: "/.netlify/functions/sellingPlan",
        method: "post",
        data: {
          id: productId,
        },
      })
        .then(result => {
          const sellingPlans =
            result.data.data.product.sellingPlanGroups.edges[0].node
              .sellingPlans.edges
          const newFrequencyOptions = sellingPlans.map(option => {
            return {
              text: option.node.name,
              value: JSON.parse(option.node.options[0].value.split(" ")[0]),
              sellingPlanId: option.node.id,
            }
          })
          frequencyMap.set(productId, newFrequencyOptions)
          setFrequencyOptions(newFrequencyOptions)
          return null
        })
        .catch(error => console.log(error))
      return false
    }

    if (productId !== "") {
      getFrequencyOptions()
    }
  }, [productId])

  return {
    minShipping: minShipping ?? 50,
    rechargeDiscount: rechargeDiscount,
    cartTitle: "Parcel",
    emptyCartMessage: "Your parcel is empty.",
    frequencyOptions: frequencyOptions,
  }
}

export default useCheckoutSettings
