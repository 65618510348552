import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import LinkList from "~components/global/linkList"
import Section from '~components/global/section'
import Newsletter from '~components/global/newsletter'
import { LogoNewStacked }  from '~components/global/svg'
import { window } from 'browser-monads'
import resolveLink from "~utils/resolveLink"
import smoothscroll from 'smoothscroll-polyfill';

const scrollTop = e =>{
  e.preventDefault()
  e.target.blur()
  window.scrollTo({top: 0, behavior: 'smooth'})
}

const Footer = ({ siteTitle }) => {

  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  const footerData = useStaticQuery(graphql`
    query {
      sanityNavigation(id: {eq: "-77a081ef-a8a5-5d1c-b163-13f8c228cde8"}) {
        footerIllo {
          asset {
            fluid {
              sizes
              srcSet
              src
              aspectRatio
              srcSetWebp
              srcWebp
            }
          }
        }
        socialMenuLinks {
          linkText
          url
        }
        address
        contactTitle
        email
        newsletterText
        newsletterTitle
        socialMenuTitle
        footerShopMenuLinks {
          ... on SanityExternalLink {
            _key
            _type
            linkText
            url
          }
          ... on SanityInternalLink {
            _key
            linkText
            _rawTo(resolveReferences: {maxDepth: 6})
          }
        }
        pageMenuLinks {
          ... on SanityExternalLink {
            _key
            _type
            linkText
            url
          }
          ... on SanityInternalLink {
            _key
            linkText
            _rawTo(resolveReferences: {maxDepth: 6})
          }
        }
        pageMenuLinksSecond {
          ... on SanityExternalLink {
            _key
            _type
            linkText
            url
          }
          ... on SanityInternalLink {
            _key
            linkText
            _rawTo(resolveReferences: {maxDepth: 6})
          }
        }
      }
    }
  `)

  const mainFooterNav = footerData.sanityNavigation.footerShopMenuLinks.map(link => {
    if(link._type === "externalLink"){
      return {
        title: link.linkText,
        url: link.url,
        external: true
      }
    }
    else{
      return {
        title: link.linkText,
        url: resolveLink(link._rawTo)
      }
    }
  })

  const pagesTop = footerData.sanityNavigation.pageMenuLinks.map(link => {
    if(link._type === "externalLink"){
      return {
        title: link.linkText,
        url: link.url,
        external: true
      }
    }
    else{
      return {
        title: link.linkText,
        url: resolveLink(link._rawTo)
      }
    }
  })

  const pagesBottom = footerData.sanityNavigation.pageMenuLinksSecond.map(link => {
    if(link._type === "externalLink"){
      return {
        title: link.linkText,
        url: link.url,
        external: true
      }
    }
    else{
      return {
        title: link.linkText,
        url: resolveLink(link._rawTo)
      }
    }
  })

  return(
    <Section name="footer xs-sans">
      <div className="footer__logo">
        <LogoNewStacked />
      </div>
      <div className="footer__mob-divider"></div>
      <nav className="footer__shop">
        <h3>Shop</h3>
        <LinkList pages={mainFooterNav} lineBreaks={true}/>
      </nav>
      <nav className="footer__pages">
        <h3>Look Around</h3>
        <div>
          <LinkList pages={pagesTop} lineBreaks={true}/>
        </div>
        <div>
          <LinkList pages={pagesBottom} lineBreaks={true}/>
        </div>
      </nav>
      <div className="footer__mob-divider footer__mob-divider--mt"></div>
      <div className="footer__social">
        <h3>{footerData.sanityNavigation.socialMenuTitle}</h3>
        <div>
          {footerData.sanityNavigation.socialMenuLinks.map(link => (
            <>
              <a href={link.url} target="_blank">{link.linkText}</a><br/>
            </>
          ))}
        </div>
        <div>
        {footerData?.sanityNavigation?.address?.split('\n').map(line => <span>{line}<br/></span>)}
        </div>
      </div>
      <div className="footer__contact">
        <h3>{footerData?.sanityNavigation?.contactTitle}</h3>
        <div>
          <a href={"mailto:" + footerData?.sanityNavigation?.email}>{footerData?.sanityNavigation?.email}</a>
        </div>
        <h3>{footerData.sanityNavigation.newsletterTitle}</h3>
        <div>
          {footerData.sanityNavigation.newsletterText}
        </div>
        <Newsletter successMessage="Done!"/>
      </div>
      <div className="footer__divider"/>
      <div className="footer__mob-newsletter">
        <Newsletter />
      </div>
      <div className="footer__copyright xs-sans">
        © {new Date().getFullYear()} Pod & Parcel
      </div>
      <div className="footer__totop">
        <button className="pill" onClick={e => scrollTop(e)}>To Top</button>
      </div>
    </Section>
  )
}
export default Footer
