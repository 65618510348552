import React, { useState, useRef, useContext, useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Helmet from "react-helmet"
import Headroom from "react-headroom"
import resolveLink from "~utils/resolveLink"
import useSiteSettings from "~utils/useSiteSettings"

import { SiteContext, useSetNavStatus, useSiteState, useCart } from '~context/siteContext'
import LinkList from "~components/global/linkList"
import { Logo, CartIcon } from "~components/global/svg"
import Redirect from "~components/redirect"

const ShopMenu = ({ links, isOpen, handleShopMenu }) => {

  const updateNavStatus = useSetNavStatus()

  return (
    <>
    <nav className={`shop-menu m-caps ${isOpen ? 'shop-menu--open' : ''}`}>
    {
      links.map( (link, i) => (
        <>
          <Link to={resolveLink(link._rawTo)} key={link._key} onClick={() => updateNavStatus('closed')}>
            <div className="shop-menu__image">
            {link.linkImage &&
              <Img fluid={link.linkImage && link.linkImage.asset.fluid} />
            }
            {link.linkHoverImage &&
              <div className="shop-menu__overlay-image">
                <Img fluid={link.linkImage && link.linkHoverImage.asset.fluid} />
              </div>
            }
            </div>
            <span>{link.linkText}</span>
          </Link>
        </>
      ))
    }
    </nav>
    <div
      className={`shop-menu-cover ${isOpen && 'shop-menu-cover--open'}`}
      tabIndex={0}
      onClick={e => handleShopMenu(e)}
      onKeyUp={e => handleShopMenu(e)}
      role="button">
      <span>Close Shop Menu</span>
      {
        // for screen readers
      }
    </div>
    </>
  )
}

const MobileMenu = ({ links, shopLinks, status, toggleShopMenu }) => {
  const updateNavStatus = useSetNavStatus()

  const renderClassList = menuStatus => {
    switch (menuStatus) {
      case 'mobile-open':
        return 'mobile-menu mobile-menu--open'
      case 'mobile-shop-open':
        return 'mobile-menu mobile-menu--sub-open mobile-menu--open'
      case 'mobile-shop-closing':
        return 'mobile-menu mobile-menu--sub-open mobile-menu--open mobile-menu--sub-closing'
      default:
        return(
          'mobile-menu'
        )
    }
  }

  return(
    <div className={renderClassList(status)}>
      <div className="mobile-menu__main">
        <div className="container">
          <nav className="mobile-menu__nav l-sans">
            <a href="#shop-menu" onClick={e => toggleShopMenu(e)}>Shop</a><br/>
            <LinkList pages={links} lineBreaks={true}/>
          </nav>
        </div>
      </div>
      <div className="mobile-menu__sub">
        {
          shopLinks.map( (link, i) => (
            <>
              <Link to={resolveLink(link._rawTo)} key={link.title + ' ' + i} onClick={() => updateNavStatus('closed')}>
                <Img fluid={link.linkImage && link.linkImage.asset.fluid}  />
                <span>{link.linkText}</span>
              </Link>
            </>
          ))
        }
      </div>
      <div className="mobile-menu__tagline">
        <h4 className="s-serif">More Taste. Less Waste.</h4>
      </div>
      <div className="mobile-menu__back">
        <button className="pill pill--solid-maroon m-caps" onClick={e => toggleShopMenu(e)}>Back to menu</button>
      </div>
    </div>
  )
}

const Header = () => {

  const updateNavStatus = useSetNavStatus()
  const { navStatus } = useSiteState()

  const { cartOpen, setCartOpen, cartCount } = useCart()

  const menuData = useStaticQuery(graphql`
    query {
      sanityNavigation(id: {eq: "-77a081ef-a8a5-5d1c-b163-13f8c228cde8"}) {
        shopMenuLinks {
          _key
          linkHoverImage {
            asset {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
          linkImage {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
                sizes
                base64
                aspectRatio
              }
            }
          }
          linkText
          _rawTo(resolveReferences: {maxDepth: 6})
        }
        menuLinksMobile {
          ... on SanityExternalLink {
            _key
            _type
            linkText
            url
          }
          ... on SanityInternalLink {
            _key
            linkText
            _rawTo(resolveReferences: {maxDepth: 6})
          }
        }
        menuLinksLeft {
          ... on SanityExternalLink {
            _key
            _type
            linkText
            url
          }
          ... on SanityInternalLink {
            _key
            linkText
            _rawTo(resolveReferences: {maxDepth: 6})
          }
        }
        menuLinksRight {
          ... on SanityExternalLink {
            _key
            _type
            linkText
            url
          }
          ... on SanityInternalLink {
            _key
            linkText
            _rawTo(resolveReferences: {maxDepth: 6})
          }
        }
      }
    }
  `)

  const rightLinks = menuData.sanityNavigation.menuLinksRight.map(link => {
    if(link._type === "externalLink"){
      return {
        title: link.linkText,
        url: link.url,
        external: true
      }
    }
    else{
      return {
        title: link.linkText,
        url: resolveLink(link._rawTo)
      }
    }
  })

  const leftLinks = menuData.sanityNavigation.menuLinksLeft.map(link => {
    if(link._type === "externalLink"){
      return {
        title: link.linkText,
        url: link.url,
        external: true
      }
    }
    else{
      return {
        title: link.linkText,
        url: resolveLink(link._rawTo)
      }
    }
  })

  const mobileLinks = menuData.sanityNavigation.menuLinksMobile.map(link => {
    if(link._type === "externalLink"){
      return {
        title: link.linkText,
        url: link.url,
        external: true
      }
    }
    else{
      return {
        title: link.linkText,
        url: resolveLink(link._rawTo)
      }
    }
  })

  function openCart(e){
    e.preventDefault()
    setCartOpen(true)
  }

  const { siteTagline } = useSiteSettings()

  const toggleShopMenu = e => {
    e.preventDefault()
    if(navStatus === 'shop-open'){
      updateNavStatus('closed')
    }
    else{
      updateNavStatus('shop-open')
    }
    // setShopMenuOpen(!shopMenuOpen)
  }

  const toggleMobileMenu = () => {
    if(navStatus === 'mobile-open'){
      updateNavStatus('closed')
    }
    else if(navStatus === 'mobile-shop-open'){
      updateNavStatus('mobile-shop-closing')
      setTimeout(() => updateNavStatus('closed'), 650)
    }
    else{
      updateNavStatus('mobile-open')
    }
  }

  const toggleMobileShopMenu = e => {
    e.preventDefault()
    e.target.blur()
    if(navStatus === 'mobile-shop-open'){
      updateNavStatus('mobile-open')
    }
    else{
      updateNavStatus('mobile-shop-open')
    }
  }

  return(
    <>
    {/* <Redirect /> */}
    <Helmet
    bodyAttributes={navStatus !== 'closed' && {
      class: 'no-scroll'
    }}
    htmlAttributes={navStatus !== 'closed' && {
      class: 'no-scroll'
    }}
    />
    <header className={`header s-caps ${navStatus === 'mobile-open' || navStatus === 'mobile-shop-open'  ? 'menu-open' : ''}`}>
      <div className="header__container">
          <div className="header__hamburger hamburger" role="button" tabIndex={0} onClick={() => toggleMobileMenu()} onKeyUp={() => toggleMobileMenu()}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="header__left">
            <a href="#shop-menu" onClick={e => toggleShopMenu(e)} className={navStatus === 'shop-open' ? 'active' : ''}>Shop</a>
            <LinkList pages={leftLinks} />
          </div>
        <Link to="/" className="header__logo">
          <Logo />
        </Link>
          <div className="header__right">
            <LinkList pages={rightLinks} />
            <button href="#cart" onClick={e => openCart(e)} className="cart-icon s-caps">
              <CartIcon />
              <span>{cartCount()}</span>
            </button>
          </div>
          <button href="#cart" onClick={e => openCart(e)} className="cart-icon cart-icon--mobile s-caps">
            <CartIcon />
            <span>{cartCount()}</span>
          </button>
      </div>
    </header>
    <Headroom disableInlineStyles={true}>
      <div className="banner s-caps">
        <span>{siteTagline}</span>
      </div>
    </Headroom>
    <MobileMenu
      links={mobileLinks}
      shopLinks={menuData.sanityNavigation.shopMenuLinks}
      status={navStatus}
      toggleShopMenu={toggleMobileShopMenu}
    />
    <ShopMenu
      links={menuData.sanityNavigation.shopMenuLinks}
      isOpen={navStatus === 'shop-open'}
      handleShopMenu={toggleShopMenu}
    />
    {}
    </>
  )
}

export default Header
