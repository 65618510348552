import axios from "axios"
import Cookies from "js-cookie"

const prepareCheckout = async (cart, code, freeItemId, frequencyMap) => {
  const lines = cart.map(item => {
    function getItemSellingPlanId() {
      if (frequencyMap.has(item._rawShopify.productId)) {
        const plans = frequencyMap.get(item._rawShopify.productId)
        const matchingPlan = plans.find(({ value }) => value === item.frequency)
        if (matchingPlan) return matchingPlan.sellingPlanId
      }
    }

    return {
      merchandiseId: `gid://shopify/ProductVariant/${item.vId}`,
      quantity: item.qty,
      ...(item.subscribe && {
        sellingPlanId: getItemSellingPlanId(),
      }),
    }
  })

  const freeItem = code
    ? {
        merchandiseId: `gid://shopify/ProductVariant/${freeItemId}`,
        quantity: 1,
      }
    : null

  const lineItems = freeItem ? [...lines, freeItem] : lines

  try {
    axios({
      url: "/.netlify/functions/createCart",
      method: "post",
      data: {
        cartInput: {
          lines: lineItems,
        },
      },
    })
      .then(result => {
        const cart = result.data.data.cartCreate.cart
        const { checkoutUrl, id } = cart

        Cookies.set("PP_CHECKOUT_ID", id)
        Cookies.set("PP_CHECKOUT_TYPE", "shopify")

        if (checkoutUrl.includes("myshopify.com")) {
          const url = checkoutUrl.replace(
            "pod-parcel.myshopify.com",
            "au.account.podandparcel.com"
          )
          window.location = url
          return
        }
        window.location = checkoutUrl
      })
      .catch(error => {
        console.error(error)
      })
  } catch (error) {
    console.error(error)
  }
}

export default prepareCheckout
