/* eslint-disable */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import useBreakpoint from "~utils/useBreakpoint"
import Helmet from "react-helmet"
import { useLocation } from '@reach/router'
import Header from "~components/header"
import Footer from "~components/footer"
import Cart from "~components/cart/cart"
import PopUp from '~components/global/popUp'
import QuizPopUp from '~components/global/quizPopUp'
import "~styles/app.scss"
import { usePopUp } from "~context/siteContext"
import { useCart, prepareProductForTracking } from "../context/siteContext"

const Layout = ({ children }) => {
  const { loaded } = useBreakpoint()
  const {countVariantChange} = usePopUp()
  const loc = useLocation()
  const { cartTotal, cart, cartLoaded } = useCart()

  useEffect(() => {

    window.ElevarDataLayer = window.ElevarDataLayer ?? []
    window.ElevarInvalidateContext?.()
    
    countVariantChange(loc)
    if(cartLoaded){
      window?.ElevarDataLayer.push({
        event: "dl_user_data",
        cart_total: (cartTotal() || 0).toString(),
        user_properties: {
          visitor_type: "guest",
        },
        ecommerce: {
          currencyCode: "AUD",
          cart_contents: {
            products: cart?.map(product => prepareProductForTracking(product)) || []
          }
        }
      });
      
    }
  }, [loc.pathname, cartLoaded])
  

  if(!loaded){
    return null
  }

  return (
    <>
      <Header />
      <main>{children}</main>
      <PopUp />
      <QuizPopUp /> 
      <Cart />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
