import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { X } from '~components/global/svg'
import { usePopUp } from "~context/siteContext"
import useSiteSettings from "~utils/useSiteSettings"
import BlockContent from '@sanity/block-content-to-react'
import Img from "gatsby-image" 
import Newsletter from '~components/global/newsletter'
import Button from '~components/global/button'

const richTextSerializers = {
  types: {
    richTextButton: ({ node }) => (
      <>
      <a href={node.href} className="pill pill--solid-orange" target={node.target ? '_blank' : '_self'}>{node.text} <span className="pill__arrow">&rarr;</span></a><br/>
      </>
    ),
    block: (props) => (
      props.node.style === 'h4' ? 
      <Heading className='m-caps'>{props?.node?.children[0]?.text}</Heading> 
      :
      BlockContent.defaultSerializers.types.block(props)
    )
  }
}

const PopUp = ({ className }) => { 
  const {closePopUp, popUpOpen, quizPopUpOpen} = usePopUp()

  const {popUpContent, popUpText, showPopUp, popUpImage, signUpPopUpText} = useSiteSettings()


  return (
    <>
      <Wrap 
        className={`pop-up ${className}`} 
        show={(showPopUp && popUpOpen) && !quizPopUpOpen}
        small={popUpContent === 'basicTextPopUp'}
      >
        <Inner small={popUpContent === 'basicTextPopUp'}>
          <Close 
            onClick={() => closePopUp()}
          ><Cross><X/></Cross></Close>
          {(popUpContent === 'basicTextPopUp') ? 
            <Content className='xs-serif'>
                <StyledImg fluid={popUpImage?.asset?.fluid}/>   
                <BlockContent blocks={popUpText} serializers={richTextSerializers} />
            </Content>
            :
            <Content>
              <Heading className='m-caps'>Join Us!</Heading>
              <NewsletterText>
                {signUpPopUpText}
              </NewsletterText>
              <StyledNewsletter successMessage="Done!" buttonText='subscribe' />
            </Content>
          }
        </Inner>
      </Wrap>
      <Overlay />
    </>
  )
}

const Wrap = styled.div`
  background-color: #FFF1E2; 
  position: fixed;
  left: 30px;
  bottom: 100px;
  box-shadow: 3px 3px 12px hsl(0deg 0% 6% / 24%);
  border-radius: 38px;
  z-index: 10;
  width: 100%;
  max-width: ${props => props.small ? '306px' : '520px'};
  opacity: ${props => props.show ? '1' : '0'};
  pointer-events: ${props => props.show ? 'all' : 'none'};
  transition: opacity 0.25s;
  ${mobile}{
    max-width: calc(100% - 80px);
    top: 24vh;
    bottom: auto;
    left: 40px;
  }
`
const Inner = styled.div`
  position: relative; 
  min-height: 200px;
  width: 100%;
  padding: ${props => props.small ? '20px' : '30px'};
  box-sizing: border-box;
  ${mobile}{
     padding: 30px;
  }
`
const Close = styled.button`
  width: 22px;
  height: 22px;
  position: absolute;
  top: 20px;
  right: 20px;
  border: 1px solid;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Cross = styled.div`
  width: 10px;
  height: 10px;
  display: flex;
`
const Heading = styled.h4`
  margin-bottom: 20px;
`
const StyledImg = styled(Img)`
  max-width: 100px; 
  margin: 0 auto 20px;
`
const Content = styled.div`
  text-align: center;
`
const NewsletterText = styled.div`
  margin-bottom: 20px;
`
const StyledNewsletter = styled(Newsletter)`
  > form {
    > div {
      flex-wrap: wrap;
      justify-content: center;
      border-bottom: none;
      input {
        width: 100%;
        height: 40px;
        border: none;
        padding: 0 20px;
        box-sizing: border-box;
        border-radius: 50px;
        margin-bottom: 20px;
        font-family: inherit;
        -webkit-appearance: none;
        border: none;
        font-style: italic;
      }
    } 
}
button {
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  box-sizing: border-box;
  padding: 0 50px;
  min-width: 240px;
  border: 1px solid;
  color: #ffffff;
  background: #EC873E;
  border-color: #EC873E;
  font-family: 'Modern Era', sans-serif;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  font-weight: 700;
  :hover{
    color: #EC873E;
    background: #ffffff;
    border-color: #EC873E;
  }
}
`
const Overlay = styled.div`
  
`
PopUp.propTypes = {
  className: PropTypes.string
}

export default PopUp