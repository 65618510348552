import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { X } from '~components/global/svg'
import { usePopUp } from "~context/siteContext"
import useSiteSettings from "~utils/useSiteSettings"
import BlockContent from '@sanity/block-content-to-react'
import Img from "gatsby-image" 
import Newsletter from '~components/global/newsletter'
import Button from '~components/global/button'
import { navigate } from "gatsby"

const richTextSerializers = {
  types: {
    richTextButton: ({ node }) => (
      <>
      <a href={node.href} className="pill pill--solid-orange" target={node.target ? '_blank' : '_self'}>{node.text} <span className="pill__arrow">&rarr;</span></a><br/>
      </>
    ),
    block: (props) => (
      props.node.style === 'h4' ? 
      <Heading className='m-caps'>{props?.node?.children[0]?.text}</Heading> 
      :
      BlockContent.defaultSerializers.types.block(props)
    )
  }
}


const QuizPopUp = ({ className }) => {

  const {closeQuizPopUp, quizPopUpOpen} = usePopUp()
  const {quizPopUpImage, quizPopUpText, quizPopUpButtonText} = useSiteSettings()

  const closeAndNavigate = () => {
    closeQuizPopUp()
    navigate('/quiz')
  }

  return (
    <>
    <Wrap 
      className={`pop-up ${className}`} 
      show={quizPopUpOpen}
    >
      <Inner>
        <Close 
          onClick={() => closeQuizPopUp()}
        ><Cross><X/></Cross></Close>
          <Content className='xs-serif'>
            <QuizImg fluid={quizPopUpImage?.asset?.fluid}/>   
            <QuizText>
              <BlockContent blocks={quizPopUpText} serializers={richTextSerializers} />
            </QuizText>
            <Button onClick={() => closeAndNavigate()}>{quizPopUpButtonText}</Button>
          </Content>
      </Inner>
    </Wrap>
    <Overlay />
  </>
  )
}

const Wrap = styled.div`
  background-color: #FFF1E2; 
  position: fixed;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  box-shadow: 3px 3px 12px hsl(0deg 0% 6% / 24%);
  border-radius: 38px;
  z-index: 10;
  width: 100%;
  max-width: 520px;
  opacity: ${props => props.show ? '1' : '0'};
  pointer-events: ${props => props.show ? 'all' : 'none'};
  transition: opacity 0.25s;
  ${mobile}{
    max-width: calc(100% - 80px);
  }
`
const Inner = styled.div`
  position: relative; 
  min-height: 200px;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  ${mobile}{
     padding: 30px;
  }
`
const Close = styled.button`
  width: 22px;
  height: 22px;
  position: absolute;
  top: 20px;
  right: 20px;
  border: 1px solid;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Cross = styled.div`
  width: 10px;
  height: 10px;
  display: flex;
`
const Heading = styled.h4`
  margin-bottom: 20px;
`
const QuizImg = styled(Img)`
  max-width: 250px;
  margin: 0 auto 1rem;
`
const Content = styled.div`
  text-align: center;
`
const NewsletterText = styled.div`
  margin-bottom: 20px;
`
const Overlay = styled.div`
  
`
const QuizText = styled.div`
  margin-bottom: 2rem;
`
QuizPopUp.propTypes = {
  className: PropTypes.string
}

export default QuizPopUp