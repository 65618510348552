import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useSetNavStatus } from '~context/siteContext'

const LinkList = ({ pages, lineBreaks }) => {
  const updateNavStatus = useSetNavStatus()
  return (
    pages.map( (page, i) => (
      <>
        {!page.external &&
          <Link to={page.url} key={page.title + ' ' + i} activeClassName="active" onClick={() => updateNavStatus('closed')}>
            {page.title}
          </Link>
        }
        {page.external &&
          <a href={page.url} key={page.title + ' ' + i}>
            {page.title}
          </a>
        }
        {lineBreaks ? <br/ > : ''}
      </>
    ))
  )
}

LinkList.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.object)
}

export default LinkList
