import React, { useRef, useState, useEffect } from "react"
import Img from "gatsby-image"
import useCheckoutSettings from "~utils/useCheckoutSettings"
import useSiteSettings from "~utils/useSiteSettings"
import useBreakpoint from "~utils/useBreakpoint"
import prepareCheckout from "~utils/prepareCheckout"
import Helmet from "react-helmet"

import { X, Loop } from "~components/global/svg"
import {
  Select,
  Incrementer,
  SubmitButton,
  RadioButton,
  TooltipIcon,
} from "~components/global/formElements"
import { useCart, useSiteState } from "~context/siteContext"

const LineItem = ({
  vId,
  title,
  subTitle,
  subscribe,
  price,
  subPrice,
  qty,
  frequency,
  fluid,
  _rawShopify,
}) => {
  const {
    setCartOpen,
    setSubcribe,
    updateFrequency,
    updateAllFrequency,
    updateQty,
    removeItem,
  } = useCart()
  const { cartOpen } = useSiteState()

  const productId = _rawShopify.productId
  const { frequencyOptions, rechargeDiscount } = useCheckoutSettings(productId)
  const { tablet } = useBreakpoint()

  return (
    <div className="line-item">
      <div className="line-item__divider" />
      <div className="line-item__header">
        <div>
          <h4 className="s-caps">{title}</h4>
          <h5 className="xs-serif">{subTitle}</h5>
        </div>
        <div>
          <h5 className="s-caps">${subscribe ? subPrice : price}</h5>
          <Incrementer
            mini={true}
            initialValue={qty}
            onChange={qty => updateQty(vId, qty)}
          />
          <button
            className="line-item__remove"
            onClick={() => removeItem(vId, "cart", _rawShopify)}
          >
            <X />
          </button>
        </div>
      </div>
      <div className="line-item__image">
        <Img fluid={fluid} />
      </div>
      {subPrice && Array.isArray(frequencyOptions) && (
        <div className="line-item__buttons">
          <RadioButton
            selected={subscribe}
            hasTooltip={true}
            secondaryIcon={
              !tablet && (
                <TooltipIcon
                  message={`Save ${rechargeDiscount}% when you subscribe right now. Cancel anytime.`}
                />
              )
            }
            mini={true}
            onClick={() => setSubcribe(vId, !subscribe)}
          >
            Subscribe & save <span>{rechargeDiscount}%</span>
          </RadioButton>
          {subscribe && (
            <Select
              name="delivery-frequency"
              mini={true}
              primaryIcon={<Loop />}
              onChange={e => updateAllFrequency(vId, e)}
              options={frequencyOptions}
              defaultValue={frequency ?? frequencyOptions[2].value}
            ></Select>
          )}
        </div>
      )}
    </div>
  )
}

const CheckoutButton = ({ style, width }) => {
  const siteState = useSiteState()
  const { cartOpen, cartTotal, cart, setCartOpen, addItem } = useCart()
  const [loading, setLoading] = useState(false)
  const {
    minSalesMotivator,
    showSalesMotivator,
    discountCode,
    freeProduct,
  } = useSiteSettings()
  const hasFreeItem = cartTotal() >= minSalesMotivator && showSalesMotivator
  const freeItemId = freeProduct?.content?.shopify?.defaultVariant?.variantId
  const loadCheckout = () => {
    setLoading(true)
    prepareCheckout(
      cart,
      hasFreeItem ? discountCode || true : false,
      freeItemId,
      siteState.frequencyMap
    )
  }
  return (
    <div
      className={
        cartOpen ? "checkout-button checkout-button--open" : "checkout-button"
      }
      style={{ ...style, width: width + "px" }}
    >
      <div className="checkout-button__info">
        <span>Subtotal</span>
        <span>${cartTotal().toFixed(2)}</span>
      </div>
      <SubmitButton
        bg={"#EC873E"}
        textColor={"#ffffff"}
        onClick={() => setCartOpen(false)}
      >
        <span>Continue Shopping</span>
      </SubmitButton>
      <SubmitButton
        bg={"#ffffff"}
        textColor={"#4B3043"}
        onClick={() => loadCheckout()}
      >
        {!loading && <span>Checkout</span>}
        {loading && <span>Loading...</span>}
      </SubmitButton>
    </div>
  )
}

const ShippingBar = ({ min, total, title, text, success, orange }) => (
  <div className={`cart__shipping ${orange ? "cart__shipping--orange" : ""}`}>
    <h5 className="s-caps">
      {title}
      {text && <TooltipIcon inline rightAlign message={text} />}
    </h5>
    {min > total && (
      <span className="xs-serif">Spend ${Math.ceil(min - total)} more</span>
    )}
    {min <= total && <span className="xs-serif">{success}</span>}
    <div>
      <div
        style={{ width: `${min <= total ? "100" : (total / min) * 100}%` }}
      />
    </div>
  </div>
)

const CartEl = () => {
  const { setCartOpen, cartOpen, cart, cartCount, cartTotal } = useCart()
  const { minShipping, cartTitle, emptyCartMessage } = useCheckoutSettings()
  const {
    minSalesMotivator,
    salesMotivatorTitle,
    salesMotivatorText,
    salesMotivatorSuccessMessage,
    showSalesMotivator,
  } = useSiteSettings()
  const cartRef = useRef()
  const total = parseFloat(cartTotal())
  const cartContainsSubs = cart.findIndex(item => item.subscribe) !== -1
  return (
    <>
      <Helmet
        bodyAttributes={
          cartOpen && {
            class: "no-scroll",
          }
        }
        htmlAttributes={
          cartOpen && {
            class: "no-scroll",
          }
        }
      />
      <div className={`cart ${cartOpen && "cart--open"}`} ref={cartRef}>
        <div className="cart__header">
          <h3 className="s-caps">
            My {cartTitle} ({cartCount()})
          </h3>
          <button onClick={() => setCartOpen(false)}>
            <X />
          </button>
        </div>
        {cartCount() > 0 && (
          <ShippingBar
            title="Free Shipping"
            min={minShipping}
            total={total}
            success="FreeShipping!"
          />
        )}
        {cartCount() > 0 && showSalesMotivator && (
          <ShippingBar
            title={salesMotivatorTitle}
            min={minSalesMotivator}
            total={total}
            text={salesMotivatorText}
            success={salesMotivatorSuccessMessage}
            orange
          />
        )}
        <div className="cart__items">
          {cart.map(item => (
            <LineItem key={"key" + item.vId} {...item} />
          ))}
        </div>
        {cartCount() === 0 && (
          <div className="cart__empty">
            <p>{emptyCartMessage}</p>
          </div>
        )}
      </div>
      <CheckoutButton
        style={cartCount() > 0 && { opacity: 1, pointerEvents: "auto" }}
        width={cartRef.current && cartRef.current.clientWidth}
      />
      <div
        className={cartOpen ? "cart-cover cart-cover--open" : "cart-cover"}
        tabIndex={0}
        onClick={() => setCartOpen(false)}
        onKeyUp={() => setCartOpen(false)}
        role="button"
      >
        <span>Close Cart</span>
        {
          //for screen readers
        }
      </div>
    </>
  )
}

export default CartEl
