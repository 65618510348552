import React, { useState } from 'react'
import { subscribe } from 'klaviyo-subscribe'

const Newsletter = ({ successMessage, buttonText, className, buttonClass }) => {
  const listId = 'P7CCZ2'
  const customFields = {}

  const [success, setSuccess] = useState(false)
  const [focused, setFocused] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget
    const { email } = form.elements
    subscribe(listId, email.value, customFields)
    .then(res => {
      form.reset()
      setSuccess(true)
    })
  }

  return(
    <div className={`${className} newsletter ${focused ? 'newsletter--focused' : ''}`}>
      {!success ? (
        <form action="#" className="newsletter__form" onSubmit={e => handleSubmit(e)}>
          <div>
            <input type="email" placeholder="Your email here" name="email" required={true} onFocus={()=> setFocused(true)} onBlur={()=> setFocused(false)}/>
            <button type="submit" className={buttonClass}>{buttonText ?? 'Go'}</button>
          </div>
        </form>
      ) : (
        <div>
          {successMessage}
        </div>
      )}
    </div>
  )
}

export default Newsletter
