import React from 'react'
import { ButtonArrow } from "~components/global/svg"
import { Link } from "gatsby"

const Button = ({ children, to, onClick, color, arrow, className, noMinWidth, href, trackingEvent }) => {
  if(to){
    return(
      <Link className={`arrow-button ${color ? 'arrow-button--' + color : ''} ${arrow ? 'arrow-button--arrow-' + arrow : ''} ${noMinWidth ? 'arrow-button--no-min-width' : ''} ${className}`} to={to} onClick={trackingEvent}>
        {children}
        <i>
          <ButtonArrow />
        </i>
      </Link>
    )
  }
  else if(onClick){
    return(
      <button className={`arrow-button ${color ? 'arrow-button--' + color : ''} ${arrow ? 'arrow-button--arrow-' + arrow : ''} ${noMinWidth ? 'arrow-button--no-min-width' : ''} ${className}`} onClick={e => onClick(e)}>
        {children}
        <i>
          <ButtonArrow />
        </i>
      </button>
    )
  }
  else if(href){
    return(
      <a 
        href={href} 
        target="_blank"
        className={`arrow-button ${color ? 'arrow-button--' + color : ''} ${arrow ? 'arrow-button--arrow-' + arrow : ''} ${noMinWidth ? 'arrow-button--no-min-width' : ''} ${className}`}
      >
        {children}
        <i>
          <ButtonArrow />
        </i>
      </a>
    )
  }
  else{
    return null
  }
}

export default Button
