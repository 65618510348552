const resolveLink = ref => {
  if(!ref){
    return null
  }
  switch (ref._type) {
    case 'group':
      return `/${ref.slug.current}`
    case 'subscribe':
      return `/${ref.slug.current}`
    case 'extrasIndex':
      return `/${ref.slug.current}`
    case 'blogIndex':
      return `/${ref.slug.current}`
    case 'product':
      //check to see whether this product has associated groups, which we'll use to build the url
      if(ref.content?.main?.defaultGroup){
        return `/${ref.content?.main?.defaultGroup?.slug?.current}/${ref.content?.main?.slug?.current}`
      }
      else if (ref.main){
        return
      }
      // this will currently return a 404
      return `/not_found`
    case 'extra':
      return `/extras/${ref.content?.main?.slug?.current}`
    case 'article':
      return `/articles/${ref.slug?.current}`
    case 'page':
      return ref.slug.current === 'home' ? '/' : `/${ref.slug.current}`
    default:
      return `/${ref.slug.current}`
  }
}

export default resolveLink
