import React from 'react'
import PropTypes from "prop-types"

const Section = ({ name, className, children, style, id }) => (
  <section className={name + ' ' + (className ?? '')} style={style} id={id}>
    <div className="container">
      {children}
    </div>
  </section>
)

Section.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default Section
